<template>
	<div class="page">
		<div class="titBox">
			<p class="title">{{lang==6?'宝爱捷（中国）: 您值得信赖的合作伙伴':'PAIG (China): your trusted partner'}}</p>
			<p class="yellowLine"></p>
		</div>

		<div class="content">
			<div class="text" v-if="lang==6">
				宝爱捷（中国）始终坚持以客户体验为核心目标，旨在提供一种无缝销售支持以及跨区域的多品牌销售及售后服务，以向集团大客户提供最佳一站式用车解决方案和卓越用户体验为矢志不渝的追求。<br>
				作为集团大客户，您可以享受到以下大客户福利：
				<ul>
					<li>量身打造的多品牌融合All-in-One企业大客户优惠方案</li>
					<li>提供富有竞争力的销售折扣方案</li>
					<li>个性化的金融支持方案</li>
					<li>车辆试乘/试驾活动</li>
					<li>最新车辆资讯</li>
					<li>车辆置换/ 回购计划</li>
					<li>大客户企业内定期车辆展示/ 使用讲解/ 服务检测</li>
					<li>维修保养上门取/ 送车服务</li>
					<li>24小时车辆道路救援</li>
					<li>保养绿色通道</li>
					<li>多方位市场合作</li>
				</ul>
				<br>
				我们期待为各类企事业单位及其员工，提供个性化定制的集团大客户方案。<br>
				包括但不限于：银行、酒店、航空公司、以及机构部门，公立大学、公立医院、研究院、使馆、电台、报社等。
			</div>
			<div class="text" v-else>
				PAIG (China) always adheres to the core objective of customer experience, aiming to provide a seamless
				sales support and cross regional multi brand sales and after-sales service, and to provide the best
				one-stop vehicle solution and excellent user experience to the group's major customers.<br>
				As a group fleet customer, you can enjoy the following fleet customer benefits:
				<ul>
					<li>Customized multi brand integration all in one enterprise fleet customer preferential scheme</li>
					<li>Provide competitive sales discount scheme</li>
					<li>Personalized financial support scheme</li>
					<li>Vehicle test drive / test drive activities</li>
					<li>Latest vehicle information</li>
					<li>Vehicle replacement / repurchase plan</li>
					<li>Regular vehicle display / use explanation / service inspection in large customer enterprises
					</li>
					<li>Maintenance door-to-door pick-up / delivery service</li>
					<li>24-hour vehicle road rescue</li>
					<li>Maintenance green channel</li>
					<li>Multi directional market cooperation</li>
				</ul>
				<br>
				We look forward to providing customized group customer solutions for various enterprises and
				institutions and their employees.<br>
				Including but not limited to: banks, hotels, airlines, and institutional departments, public
				universities, public hospitals, research institutes, embassies, radio stations, newspapers, etc.
			</div>
			<div class="bottom">
				<p v-if="info.title">{{info.title}}</p>
				<div class="codeBox" v-if="info.atlas">
					<img :src="item" v-for="(item,index) in info.atlas" :key="index" />
				</div>
				<div v-else style="color: #97233F;margin-top: 20px;font-weight: bold;" class="phone">
					{{lang==6?'大客户合作联系： ':'Fleet Cooperation Contact: '}} 950789011-8 </div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {
				info: ''
			}
		},
		methods: {
			getInfo() {
				this.$api.getConfigure({
					type: 10
				}).then(res => {
					res.data.atlas ? res.data.atlas = res.data.atlas.split('|') : ''
					this.info = res.data
				})
			},
		}

	}
</script>

<style lang="scss" scoped>
	.page {
		padding: 171px 0 111px;
		position: relative;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px 40px;
	}

	.title {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 32px;
		font-weight: bold;
		margin-bottom: 20px;
		text-transform: uppercase;
		color: #FFF;
	}

	.titBox {
		padding: 0 111px;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	.bottom {
		p {
			font-family: Montserrat-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			line-height: 60px;
			color: #B5A191;
			border-bottom: 2px solid #B5A191;
			display: inline-block;
			margin: 100px 0;
			text-transform: uppercase;
		}

		.codeBox {
			text-align: center;

			img {
				width: 210px;
				height: 210px;
				display: inline-block;
				margin: 0 100px;
				padding: 10px;
				border: 1px solid #B5A191;
			}
		}
	}

	ul {
		margin-left: 25px;

		li::marker {
			color: #97233F;
			font-size: 30px;
		}
	}

	.phone {
		font-size: 36px;
	}
</style>